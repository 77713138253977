<template>
  <component :is="currentComponent"></component>
</template>

<script>
export default {
  name: 'registerPage',
  data() {
    return {};
  },
  computed: {
    currentComponent() {
      return () => this.$platform.pageLoader(this.regulator, 'register/Register');
    }
  }
};
</script>
